* {
  box-sizing: border-box
}

body {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}

/*fonts*/
@font-face {
  font-family: 'TildaSans';
  src: url('./fonts/TildaSans-Bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'TildaSans';
  src: url('./fonts/TildaSans-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'TildaSans';
  src: url('./fonts/TildaSans-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'TildaSans';
  src: url('./fonts/TildaSans-Light.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'TildaSans';
  src: url('./fonts/TildaSans-ExtraBold.woff2') format('woff2');
  font-weight: 800;
}


.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
  margin-right: auto;
  margin-left: auto;
  padding: 16px 80px;
  font-family: 'TildaSans';
}

.header-title {
  display: flex;
  flex-direction: column;
}

.header_title {
  cursor: pointer;
}

.title {
  font-size: 18px;
  font-weight: 900;
}

.subtitle {
  font-size: 14px;
  font-weight: 500;
}

.form {
  max-width: 625px;
  margin-right: auto;
  margin-left: auto;
  padding: 48px;
  text-align: center;
  background-color: #F7F8F5;
  font-family: 'TildaSans';
}

.form_title {
  margin: 0;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 400;
  color: #494B42;
}

.wrap {
  display: flex;
  flex-direction: column;
  -ms-flex-align: start;
}

.input_style {
  width: 100%;
  padding: 20px;
  outline: none;
  border: 1px solid #C9D89566;
  border-radius: 2px;
}

.input_style::placeholder {
  font-size: 18px;
  font-weight: 500;
  color: #CFD0CA;
}

.input_style-err {
  width: 100%;
  padding: 20px;
  outline: none;
  border: 1px solid #E54D4D;
  border-radius: 2px;
}

.input_wrap {
  text-align: start;
}

.btn_style {
  width: 100%;
  margin-top: 24px;
  padding: 20px;
  outline: none;
  border: none;
  border-radius: 2px;
  background-color: #C9D895;
  color: #494B42;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.label_style {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #878980;
}

.label_password {
  display: inline-block;
  margin-top: 24px;
}

.err_message {
  margin: 0;
  margin-left: 20px;
  color: #d26262;
  font-size: 12px;
  font-weight: 400;
}

.list_rooms {
  background-color: #F7F8F5;
  padding: 15px 30px 15px 50px;
  background-image: url('./images/Vector.svg');
  background-repeat: no-repeat;
  background-position: 20px 20px;
  cursor: pointer;
}

.user_avatar {
  position: relative;
  padding: 15px 20px 5px 50px;
  background-color: #F7F8F5;
  border-radius: 99px 2px 2px 99px;
  color: #878980;
  font-size: 16px;
  font-weight: 400;
  background-image: url('./images/UserAvatar.png');
  background-repeat: no-repeat;
  background-position: 1px center;
  text-align: center;
}

.admin_name {
  margin-right: 5px;
}

.icon_select {
  position: absolute;
  top: 19px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 70px;
  right: 80px;
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: 0.3s;
  border: 1px solid rgba(0, 65, 102, 0.2);
  box-shadow: rgba(0, 32, 51, 0.12);
  border-radius: 2px;
  padding: 24px 8px 0 15px;
  background-color: #fff;
  z-index: 1;
}

.dropdown-menu.active {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.dropdown-item {
  color: #494B42;
  font-size: 18px;
  margin-right: 40px;
  cursor: pointer;
}

.test {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px;
}

.test:hover {
  border-radius: 2px;
  background-color: #F7F8F5;
}

.cover-menu_wrap {
  padding: 0 80px 48px;
}

.blur {
  filter: blur(4px);
}

.cover-margin {
  margin-bottom: 48px;
}

.groups {
  display: flex;
  flex-direction: column;
  background-color: #F7F8F5;
  border-radius: 2px;
  padding: 48px 90px;
  background-image: url('./images/groups.png');
  background-repeat: no-repeat;
  background-position: 48px center;
  max-width: 405px;
  cursor: pointer;
}

.events {
  display: flex;
  flex-direction: column;
  background-color: #F7F8F5;
  border-radius: 2px;
  padding: 48px 90px;
  background-image: url('./images/events.png');
  background-repeat: no-repeat;
  background-position: 48px center;
  max-width: 405px;
  cursor: pointer;
}

.rest {
  display: flex;
  flex-direction: column;
  background-color: #F7F8F5;
  border-radius: 2px;
  padding: 48px 90px;
  background-image: url('./images/rest.png');
  background-repeat: no-repeat;
  background-position: 48px center;
  max-width: 405px;
  cursor: pointer;
}

.menu-title {
  display: block;
  margin-left: 60px;
  margin-bottom: 4px;
  font-family: 'TildaSans';
  font-size: 20px;
  font-weight: 700;
  color: #494B42;
}

.menu-edit {
  display: block;
  margin-left: 60px;
  font-family: 'TildaSans';
  font-size: 16px;
  font-weight: 400;
  color: #878980;
}

.menu-items {
  display: flex;
  justify-content: space-between;
}

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 32, 51, 0.15);
  backdrop-filter: blur(4px);
}

.modal {
  position: relative;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 625px;
  padding: 48px;
  text-align: center;
  font-family: 'TildaSans';
  border-radius: 2px;
  z-index: 1;
}

button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn-wrap {
  display: flex;
  justify-content: space-between;
}

.btn-exit {
  width: 50%;
  padding: 20px;
  background-color: #E54D4D;
  color: #fff;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 600;
  margin-right: 8px;
  cursor: pointer;
  font-family: 'TildaSans';
}

.btn-cancel {
  width: 50%;
  padding: 20px;
  background-color: #fff;
  color: #878980;
  border: 1px solid #878980;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'TildaSans';
}

.modal-title {
  margin: 0;
  margin-bottom: 24px;
  font-family: 'TildaSans';
  font-size: 32px;
  font-weight: 400;
  color: #494B42;
}

.modal-question {
  margin: 0;
  margin-bottom: 10px;
  color: #878980;
  font-size: 18px;
  font-weight: 500;
}

.modal-text {
  margin: 0;
  margin-bottom: 33px;
  color: #CFD0CA;
  font-size: 14px;
  font-weight: 500;
}

.btn-close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.modal-position {
  position: absolute;
  top: 0 center;

}

.test2 {
  position: relative;
}

.test3 {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.test4 {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.group-title-wrap {
  display: flex;
  align-items: center;
  padding: 0 80px;
  font-family: 'TildaSans';
}

.group-title {
  margin-right: 18px;
  font-size: 32px;
  font-weight: 700;
  color: #494B42;
}

.btn-plus {
  margin-right: 6px;
  cursor: pointer;
}

.btn-basket {
  cursor: pointer;
}

.calendar {
  display: flex;
}

.calendar-wrap {
  display: flex;
  align-items: center;
  padding: 20px 80px;
  font-family: 'TildaSans';
}

.enter-group {
  background-color: #494B42;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 3px 20px;
  cursor: pointer;
}

.text {
  font-family: 'TildaSans';
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  color: #494B42;
  padding: 95px;
}

.react-datepicker__input-container input {
  width: 250px;
  padding: 10px;
  background-color: #878980;
  border: none;
  outline: none;
  border-radius: 2px;
  color: #CFD0CA;
  font-size: 13px;
}

.card-container {
  padding: 20px 80px;
}

.card-wrap-green {
  display: flex;
  align-items: center;
  max-width: 405px;
  padding: 52px 48px;
  background-color: #C9D895;
  font-family: 'TildaSans';
  border-radius: 2px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;

}

.card-item {
  margin-bottom: 24px;
  cursor: pointer;
}

.card-item:not(:last-child) {
  margin-right: 32px;
}

.card-info {
  display: flex;
  flex-direction: column;
}

.group-img {
  margin-right: 16px;
}

.date-group {
  font-size: 20px;
  font-weight: 700;
  color: #494B42;
  margin-bottom: 5px;
}

.status {
  font-size: 16px;
  font-weight: 400;
  color: #494B42;
}

.card-wrap-gray {
  display: flex;
  align-items: center;
  max-width: 405px;
  padding: 52px 48px;
  background-color: #F7F8F5;
  font-family: 'TildaSans';
  border-radius: 2px;
}

.period-wrap {
  padding: 16px 80px;
  font-family: 'TildaSans';
}

.group-period {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.date-group-period {
  margin-right: 15px;
  font-size: 32px;
  font-weight: 700;
  color: #494B42;
}

.btn-delete-group {
  cursor: pointer;
}

.send-code {
  max-width: 265px;
  margin-right: 15px;
  text-align: center;
  padding: 5px 10px;
  background-color: #F7F8F5;
  color: #494B42;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.send-code:hover {
  background-color: #E7EADE;
}

.list-wrap {
  padding: 16px 80px;
  font-family: 'TildaSans';
}

.header-list-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.list-item {
  font-size: 18px;
  font-weight: 900;
  color: #494B42;
}

.list-item-home {
  padding: 0 40px 0 0;
}

.list-item-room {
  padding: 0 50px 0 0;
}

.list-item-fio {
  padding: 0 300px 0 0;
}

.list-item-mail {
  padding: 0 250px 0 0;
}

.list-item-start {
  padding: 0 40px 0 0;
}

.list-item-end {
  padding: 0 50px 0 0;
}

.person-list-wrap {
  display: flex;
  align-items: center;
  background-color: transparent;
}

.person-list-wrap:nth-child(2n) {
  display: flex;
  align-items: center;
  background-color: #F7F8F5;
}

/* .person-item {
  cursor: pointer;
} */

.person-item-home {
  padding: 8px 30px;
  background-color: #CACEC0;
  width: 70px;
}

.person-item-room {
  padding: 8px 22px;
  background-color: #E7EADE;
  width: 65px;
}

.person-item-fio {
  width: 345px;
  padding: 8px 30px;
  /* background-color: #F7F8F5; */
  cursor: pointer;
}

.person-item-fio:hover {
  background-color: #E7EADE;
}

.person-item-mail {
  width: 350px;
  padding: 8px 30px;
  /* background-color: #F7F8F5; */
}

.person-item-start {
  padding: 8px 10px;
  /* background-color: #F7F8F5; */
}

.person-item-end {
  padding: 8px 65px;
  /* background-color: #F7F8F5; */
}

.delete-person {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 10px;
  margin-left: auto;
  /* background-color: #F7F8F5; */
  cursor: pointer;
}

.delete-person:disabled {
  /* background-color: #50515081; */
  cursor: not-allowed;
}

.cancel-dish-btn:disabled {
  cursor: not-allowed;
}


.add-person {
  background-color: #E7EADE;
  height: 40px;
}

.add-person-bg {
  cursor: pointer;
}

.settle-person {
  padding: 8px 36px;
  background-color: #878980;
  color: #C9D895;
  font-weight: 600;
  cursor: pointer;
}

.settle-person:disabled {
  background-color: #50515081;
  color: #505150a8;
  cursor: not-allowed;
}

.modal-wrap-add-group {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(9, 10, 8, 0.5);
  backdrop-filter: blur(4px);
  transform: scale(0);
}

.modal-wrap-add-group.active {
  transform: scale(1);
  z-index: 2;
}



.modal-add-group {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #494B42;
  margin-left: auto;
  margin-right: auto;
  max-width: 842px;
  padding: 48px;
  /* text-align: center; */
  font-family: 'TildaSans';
  border-radius: 2px;
  z-index: 3;
}

.modal-add-group-title {
  font-size: 32px;
  color: #fff;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.dropzone {
  margin-bottom: 20px;
  padding: 30px;
  border: 2px dashed rgba(201, 216, 149, 0.4);
  border-radius: 2px;
  text-align: center;
}

.flex-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.files-btn-wrap {
  display: flex;
  justify-content: flex-end;
}

.modal-style {
  margin-bottom: 20px;
}

.modal-style2 {
  margin-bottom: 10px;
}

.modal-style3 {
  margin-bottom: 20px;
}

.date-period-start,
.date-period-end {
  color: #CFD0CA;
}

.style-text {
  margin: 0;
  margin-bottom: 10px;
  color: #F7F8F5;
  font-size: 18px;
  font-weight: 400;
}

.style-text2 {
  margin: 0;
  margin-bottom: 17px;
  color: #CFD0CA;
  font-size: 14px;
}

.btn-upload {
  padding: 10px 20px;
  background-color: #595A54;
  color: #C9D895;
  border-radius: 2px;
  border: none;
  outline: none;
  margin-bottom: 10px;
}

.modal-btn-cancel {
  background-color: transparent;
  border: none;
  outline: none;
  color: #F7F8F5;
  font-family: 'TildaSans';
  font-size: 18px;
  padding: 10px 30px;
  cursor: pointer;
}

.modal-btn-save {
  background-color: #595A54;
  border: none;
  outline: none;
  color: #878980;
  font-family: 'TildaSans';
  font-size: 18px;
  padding: 10px 30px;
  border-radius: 2px;
  cursor: pointer;
}

.modal-btn-save.active {
  background-color: #C9D895;
  border: none;
  outline: none;
  color: #494B42;
  font-family: 'TildaSans';
  font-size: 18px;
  padding: 10px 30px;
  border-radius: 2px;
}

.add-group-btn-close {
  cursor: pointer;
}

.add-person-mail {
  width: 100%;
}

.select-home {
  width: 250px;
}

.select-room {
  width: 250px;
}

.style-option {
  width: 240px;
}

.label-style-person {
  font-size: 14px;
  color: #CFD0CA;
  margin-bottom: 5px;
}

.add-person-fio,
.add-person-mail,
.select-home,
.select-room {
  padding: 10px;
  background-color: #878980;
  border: none;
  outline: none;
  border-radius: 2px;
  color: #CFD0CA;
  font-size: 13px;
}

.margin-right:not(:last-child) {
  margin-right: 10px;
}

.fio-wrap {
  margin-bottom: 20px;
}

.add-person-mail {
  margin-bottom: 20px;
}

.home-room {
  margin-bottom: 20px;
}

.add-person-fio::placeholder {
  color: #aaaaa7;
}

.add-person-mail::placeholder {
  color: #aaaaa7;
}

.btn-wrap-add-person {
  display: flex;
  justify-content: flex-end;
}

.btn-add-person-cancel {
  background-color: transparent;
  border: none;
  outline: none;
  color: #F7F8F5;
  font-family: 'TildaSans';
  font-size: 18px;
  padding: 10px 30px;
  cursor: pointer;
}

.btn-add-person-save {
  background-color: #595A54;
  border: none;
  outline: none;
  color: #878980;
  font-family: 'TildaSans';
  font-size: 18px;
  padding: 10px 30px;
  border-radius: 2px;
}

.btn-add-person-save-active {
  background-color: #C9D895;
  border: none;
  outline: none;
  color: #494B42;
  font-family: 'TildaSans';
  font-size: 18px;
  padding: 10px 30px;
  border-radius: 2px;
}

.wrap-datepicker {
  margin-bottom: 20px;
}

.add-person-btn-close {
  cursor: pointer;
}

.select-home option {
  background-color: #E54D4D;
}

.modal-wrap-delete-person {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(247, 248, 245, 0.5);
  backdrop-filter: blur(4px);
  transform: scale(0);
}

.modal-wrap-delete-person.active {
  transform: scale(1);
}

.modal-delete-person {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  max-width: 842px;
  padding: 48px;
  text-align: center;
  font-family: 'TildaSans';
  border-radius: 2px;
  z-index: 1;
}

.modal-delete-person-title {
  color: #494B42;
  font-size: 32px;
}

.modal-style-delete-person {
  /* display: flex; */
  /* align-items: center; */
  margin-bottom: 20px;
  /* justify-content: space-between; */
}

.add-person-btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.center {
  text-align: center;
}

.delete-person-question {
  margin: 0;
  margin-bottom: 10px;
  font-size: 18px;
  color: #595A54;
}

.delete-person-text {
  margin: 0;
  margin-bottom: 20px;
  font-size: 14px;
  color: #878980;
}

.btn-wrap-delete-person {
  display: flex;
  justify-content: space-between;
}

.btn-delete-person {
  margin-right: 10px;
  padding: 5px 60px;
  border: none;
  outline: none;
  background-color: #E54D4D;
  color: #fff;
  border-radius: 2px;
  font-family: 'TildaSans';
  font-size: 18px;
  cursor: pointer;
}

.btn-cancel-person {
  padding: 5px 60px;
  border: 1px solid #CFD0CA;
  outline: none;
  background-color: transparent;
  color: #CFD0CA;
  border-radius: 2px;
  font-family: 'TildaSans';
  font-size: 18px;
  cursor: pointer;
}

.modal-wrap-delete-group {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(9, 10, 8, 0.5);
  backdrop-filter: blur(4px);
  transform: scale(0)
}

.modal-wrap-delete-group.active {
  transform: scale(1)
}

.month-picker-select {
  background-color: #F7F8F5;
  border: 1px solid #E7EADE;
  outline: none;
  padding: 10px 100px 10px 10px;
  border-radius: 2px;
  font-family: 'TildaSans';
  cursor: pointer;
  font-size: 16px;
}

a {
  text-decoration: none;
}

/* events */
.modal-wrap-add-event {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(9, 10, 8, 0.5);
  backdrop-filter: blur(4px);
  transform: scale(0);
}

.modal-wrap-add-event.active {
  transform: scale(1);

}

.modal-wrap-checkin-person {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(118 123 113 / 50%);
  backdrop-filter: blur(4px);
  transform: scale(0);
}

.modal-wrap-checkin-person.active {
  transform: scale(1);
  z-index: 2;
}

.modal-add-event {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #494B42;
  margin-left: auto;
  margin-right: auto;
  max-width: 842px;
  padding: 48px;
  text-align: center;
  font-family: 'TildaSans';
  border-radius: 2px;
  z-index: 1;
}

.btn-add-event-wrap {
  display: flex;
}

.btn-add-event {
  margin-right: 10px;
  padding: 15px 60px;
  background-color: #878980;
  color: #F7F8F5;
  font-size: 16px;
  font-weight: 600;
  font-family: 'TildaSans';
}

.btn-create-new-event {
  padding: 15px 100px;
  background-color: #C9D895;
  color: #494B42;
  font-size: 16px;
  font-weight: 600;
  border-radius: 2px;
  font-family: 'TildaSans';
}

.modal-add-event-title {
  margin-right: auto;
  font-size: 32px;
  color: #F7F8F5;
  border-radius: 2px;
}

.add-event-btn-close {
  position: absolute;
  top: 55px;
  right: 45px;
  cursor: pointer;
}

.filter-wrap {
  display: flex;
  align-items: center;
}

.event-card-container {
  display: flex;
  /* justify-content: space-between; */
  padding: 0 80px;
  font-family: 'TildaSans';
  height: 440px;
  margin-bottom: 50px;
}

.event-card-wrap {
  position: relative;
  width: 200px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #F7F8F5;
  border-radius: 2px;
  margin-right: 10px;
  height: fit-content;
}

.geo {
  margin-bottom: 10px;
  font-size: 12px;
  color: #494B42;
}

.geo-text {
  margin-bottom: 10px;
  font-size: 14px;
  color: #494B42;
}

.event-edit-btn {
  width: 100%;
  padding: 5px;
  background-color:
    #878980;
  border-radius: 2px;
  color:
    #F7F8F5;
  font-size: 14px;
  font-weight: 600;
}

.event-edit-btn:disabled {
  cursor: not-allowed;
}

.event-img {
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
  width: 180px;
  height: 180px;
}

.event-name {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #494B42;
}

.event-hour {
  position: absolute;
  padding: 4px;
  top: 155px;
  left: 23px;
  font-size: 12px;
  background-color: #F7F7F9;
}

.event-date {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  color: #494B42;
}

.flex-column {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.event-wrap {
  display: flex;
}

.create-event-title {
  font-size: 32px;
  color: #F7F8F5;
}

.points {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 20px;
  font-size: 12px;
  background-color: #F7F8F5;
  color: #878980;
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px rgba(79, 68, 100, 0.40);
}

.add-event-name,
.add-event-telegram,
.add-event-descr,
.add-event-date,
.add-event-repeat,
.add-event-time-start,
.add-event-time-end,
.add-event-place,
.add-event-meet {
  width: 270px;
  border: none;
  outline: none;
  padding: 10px;
  background-color: #878980;
}

.add-event-name::placeholder {
  font-size: 14px;
  font-family: 'TildaSans';
  color: #CFD0CA;
}

.add-event-place::placeholder {
  font-size: 14px;
  font-family: 'TildaSans';
  color: #CFD0CA;
}

.add-event-meet::placeholder {
  font-size: 14px;
  font-family: 'TildaSans';
  color: #CFD0CA;
}

.add-event-telegram::placeholder {
  font-size: 14px;
  font-family: 'TildaSans';
  color: #CFD0CA;
}

.add-event-descr::placeholder {
  font-size: 14px;
  font-family: 'TildaSans';
  color: #CFD0CA;
}

.add-event-descr {
  width: 590px;
  height: 150px;
  resize: none;
  background-color: #878980;
}

.margin-bottom {
  margin-bottom: 20px;
}

.margin-top {
  margin-top: 20px;
}

.btn-event-cancel {
  background-color: transparent;
  padding: 10px 30px;
  font-family: 'TildaSans';
  font-size: 18px;
  font-weight: 600;
  color: #F7F8F5;
  margin-right: 10px;
}

.btn-event-save {
  background-color: #595A54;
  padding: 10px 30px;
  font-family: 'TildaSans';
  font-size: 18px;
  font-weight: 600;
  color: #878980;
  border-radius: 2px;
}

.btn-event-save.active {
  background-color:
    #C9D895;
  padding: 10px 30px;
  font-family: 'TildaSans';
  font-size: 18px;
  font-weight: 600;
  color: #494B42;
  border-radius: 2px;
}

.btn-event-wrap {
  display: flex;
  justify-content: flex-end;
}

.descr-title-wrap {
  margin-bottom: 20px;
  padding: 0 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.descr-title {
  margin-right: 10px;
  font-family: 'TildaSans';
  font-size: 30px;
  font-weight: 700;
  color:
    #494B42;
}

.btn-descr-edit {
  margin-right: 10px;
  cursor: pointer;
}

.btn-descr-cancel {
  margin-right: 10px;
  cursor: pointer;
}

.btn-descr-delete {
  cursor: pointer;
}

.descr-time-date {
  margin-bottom: 30px;
  padding: 0 80px;
  font-family: 'TildaSans';
  font-size: 18px;
  color: #4C4360;
}

.descr-time {
  margin-right: 18px;
  font-weight: 400;
  color: #878980;
}

.descr-date {
  color: #1C0A3F;
}

.descr-info {
  padding: 0 80px;
  margin-bottom: 30px;
}

.descr-item {
  margin-right: 10px;
  padding: 10px;
  background-color:
    #F7F8F5;
  color: #878980;
  font-size: 12px;
  font-family: 'TildaSans';
  border-radius: 8px;
}

.descr-text {
  padding: 0 80px;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: 'TildaSans';
  color: #494B42;
}

.btn-cancel-event {
  margin-right: 10px;
  padding: 5px 90px;
  border: 1px solid #E54D4D;
  outline: none;
  background-color: transparent;
  color: #E54D4D;
  border-radius: 2px;
  font-family: 'TildaSans';
  font-size: 18px;
  cursor: pointer;
}

.btn-not-cancel {
  padding: 5px 90px;
  border: 1px solid #E7E5EB;
  outline: none;
  background-color: transparent;
  color: #4C4360;
  border-radius: 2px;
  font-family: 'TildaSans';
  font-size: 18px;
  cursor: pointer;
}

.modal-select-event {
  width: 700px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #494B42;
  margin-left: auto;
  margin-right: auto;
  max-width: 842px;
  padding: 48px;
  font-family: 'TildaSans';
  border-radius: 2px;
  z-index: 1;
}

.select-event {
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 2px;
  background-color: #878980;

}

.select-event-label {
  color: #F7F8F5;
  margin-bottom: 10px;
}

.descr-info-wrap {
  padding: 0 80px;
  display: flex;
}

.modal-delete-event {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  max-width: 842px;
  padding: 48px;
  text-align: center;
  font-family: 'TildaSans';
  border-radius: 2px;
  z-index: 1;
}

.modal-cancel-event {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  max-width: 842px;
  padding: 48px;
  text-align: center;
  font-family: 'TildaSans';
  border-radius: 2px;
  z-index: 1;
}

.select-event {
  width: 100%;
  padding: 10px;
  outline: none;
  /* border: 1px solid #DCDCEA; */
  border-radius: 2px;
  /* margin-bottom: 20px; */
}

.select-event-label {
  margin-bottom: 10px;
}

.pagination-container {
  padding: 0 80px;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.pagination-list {
  display: flex;
  justify-content: center;
  list-style: none;
  align-items: center;
}

.card-wrap {
  display: flex;
}

.card-wrap-all {
  display: flex;
  flex-direction: column;
}

.card-wrap-all:not(:last-child) {
  margin-right: 10px;
}

.btn-left {
  margin-right: 30px;
}

.btn-left-rooms {
  margin-right: 30px;
}

.btn-right {
  margin-left: 30px;
}

.btn-right-rooms {
  margin-left: 30px;
}

.page-number {
  font-family: 'TildaSans';
  font-size: 18px;
  color: #4C4360;
}

.page-number-rooms {
  font-family: 'TildaSans';
  font-size: 18px;
  color: #CFD0CA;
}

.modal-wrap-add-event-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(9, 10, 8, 0.5);
  backdrop-filter: blur(4px);
  transform: scale(0);
  z-index: 2;
}

.modal-wrap-add-event-btn.active {
  transform: scale(1);
}

.modal-wrap-cancel-event {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(9, 10, 8, 0.5);
  backdrop-filter: blur(4px);
  transform: scale(0);
}

.modal-wrap-cancel-event.active {
  transform: scale(1);
}

.user-pic {
  margin-right: 5px;
}

.descr-date {
  margin-right: 20px;
  font-family: 'TildaSans';
  font-size: 20px;
  font-weight: 700;
  color: #494B42;
}

.modal-wrap-checkin-group {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(9, 10, 8, 0.5);
  backdrop-filter: blur(4px);
  transform: scale(0);
}

.modal-wrap-checkin-group.active {
  transform: scale(1);
}

.modal-wrap-houses {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(9, 10, 8, 0.5);
  backdrop-filter: blur(4px);
  transform: scale(0);
  font-family: 'TildaSans';
}

.modal-wrap-houses.active {
  transform: scale(1);
  z-index: 1;
}

.modal-houses {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #494B42;
  margin-left: auto;
  margin-right: auto;
  width: 1280px;
  padding: 48px;
  text-align: center;
  font-family: 'TildaSans';
  border-radius: 2px;
  z-index: 1;
}

.house-number-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.house-number {
  padding: 8px 12px;
  color: #E7EADE;
  background-color: #595A54;
  font-size: 18px;
  cursor: pointer;
}

.house-number.active {
  background-color: #E7EADE;
  color: #595A54;
}

.house-number:not(:last-child) {
  margin-right: 10px;
}

.house-number.active {
  color: #595A54;
  background-color: #E7EADE;
}

.modal-houses-close {
  position: absolute;
  top: 50px;
  right: 50px;
  cursor: pointer;
}

.room-card {
  width: 280px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.room-header {
  padding: 15px;
  background-color: #878980;
  color: #F7F8F5;
}

.room-header.free {
  background-color:
    #C9D895;
  color: #494B42;
}

.room-number {
  font-size: 20px;
  font-weight: 700;
}

.room-user {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 15px;
  background-color: #595A54;
  min-height: 80px;
}

.user-icon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.user-fio {
  font-size: 12px;
  font-weight: 700;
  color: #F7F8F5;
  margin-bottom: 5px;
}

.fio-mail {
  font-size: 12px;
  font-weight: 400;
  color: #F7F8F5;
  margin-right: auto;
}

.user-free {
  color: #CFD0CA;
  font-size: 12px;
  font-weight: 700;
  margin-right: auto;
}

.rooms-card-container {
  display: flex;
  flex-wrap: wrap;
}

.file-name {
  font-family: 'TildaSans';
  list-style: none;
  color: #f7f8f59c;
  font-size: 14px;
}

.fio-title {
  padding: 0 80px;
  margin-bottom: 20px;
  font-family: 'TildaSans';
  color: #494B42;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.last-name-user,
.first-name-user,
.surname-user {
  margin-right: 10px;
}

.user-edit-img {
  cursor: pointer;
}

.user-info {
  padding: 0 80px;
  margin-bottom: 20px;
  font-family: 'TildaSans';
  color: #494B42;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.user-login,
.user-code,
.user-date,
.user-home,
.user-room,
.user-points {
  display: flex;
  align-items: center;
  margin-right: 70px;
}

.user-points {
  cursor: pointer;
}

.user-points:hover {
  background-color: #F7F8F5;
}

.log,
.code,
.date-title,
.home-title,
.room-title,
.points-title {
  font-weight: 700;
  margin-bottom: 3px;
}

.user-img-mr {
  margin-right: 10px;
}

.user-events-container {
  display: flex;
  padding: 0 80px;
  height: 440px;
  margin-bottom: 50px;
  font-family: 'TildaSans';
}

.free-house {
  background-color: #C9D895;
  color: #494B42;
}

.edit-pointer {
  cursor: pointer;
}

.modal-points {
  width: 700px;
  font-size: 32px;
  color: #F7F8F5;
}

.points-text {
  font-size: 18px;
  color: #CFD0CA;
}

.add-points-input {
  width: 100%;
  border: none;
  outline: none;
  background-color: #878980;
  padding: 10px;
  margin-bottom: 10px;
}

.add-points-input::placeholder {
  color: #CFD0CA;
  font-size: 18px;
}

.set-person-checkin {
  color: #F2F2F2;
}

.wrap-checkin-title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.select-person-checkin {
  width: 100%;
  background-color: #595A54;
  padding: 10px;
  border: none;
  outline: none;
  margin-bottom: 20px;
}

.wraps {
  display: flex;
  align-items: center;
}

/* ресторан */
.container-rest {
  display: flex;
  padding: 0 80px;
  font-family: 'TildaSans';
}

.wrap-rest-btn {
  padding: 0 80px;
  font-family: 'TildaSans';
  margin-bottom: 20px;
}

.rest-title {
  color: #494B42;
}

.create-menu {
  background-color: #F7F8F5;
  color: #494B42;
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
}

.create-menu:hover {
  background-color: #C9D895;
  color: #494B42;
}

.create-menu.unactive {
  background-color: #F7F8F5;
  color: #494B42;
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
}

.all-menu {
  background-color: #F7F8F5;
  color: #494B42;
  font-size: 18px;
  padding: 10px 50px;
  cursor: pointer;
}

.all-menu:hover {
  background-color: #C9D895;
  color: #494B42;
}

.all-menu.active {
  background-color: #C9D895;
  color: #494B42;
  font-size: 18px;
  padding: 10px 50px;
  cursor: pointer;
}

.courses-btn-wrap {
  padding: 0 80px;
  display: flex;
  font-family: 'TildaSans';
  margin-bottom: 20px;
}

.btn-course {
  padding: 10px 30px;
  background-color: #FEFFFF;
  color: #494B42;
  font-size: 18px;
}

.btn-course.active {
  padding: 10px 30px;
  background-color: #C9D895;
  color: #494B42;
  font-size: 18px;
}

.courses-time-wrap {
  padding: 0 80px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.input-time {
  padding: 5px 10px;
  margin-right: 15px;
  border: 1px solid #494b4273;
  border-radius: 2px;
}

#course-start:before {
  content: 'Начало:';
  margin-right: .6em;
  color: #494b4273;
  font-size: 18px;
  font-family: 'TildaSans';
}

#course-end:before {
  content: 'Конец:';
  margin-right: .6em;
  color: #494b4273;
  font-size: 18px;
  font-family: 'TildaSans';
}

#course-end {
  margin-left: 15px;
}

.courses-title-wrap {
  padding: 0 80px;
  display: flex;
  font-family: 'TildaSans';
  margin-bottom: 20px;
  align-items: center;
}

.courses-title {
  font-size: 20px;
  color: #494B42;
  margin-right: 15px;
}

.courses-card-container {
  padding: 0 80px;
  display: flex;
  flex-wrap: wrap;
}

.course-card {
  position: relative;
  width: 190px;
  display: flex;
  flex-direction: column;
  background-color: #F7F8F5;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  height: fit-content;
}

.for-all-checkbox {
  position: absolute;
  top: 5px;
  right: 5px;
}

.course-card-title {
  display: block;
  color: #494B42;
  font-family: 'TildaSans';
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  max-width: 130px;
  word-wrap: break-word;
}

.course-card-descr {
  display: block;
  color: #494B42;
  font-family: 'TildaSans';
  font-size: 600;
  font-size: 14px;
  margin-bottom: 5px;
  max-width: 150px;
  word-wrap: break-word;
}

.course-edit,
.course-delete {
  cursor: pointer;
}

.modal-wrap-add-course {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(9, 10, 8, 0.5);
  backdrop-filter: blur(4px);
  transform: scale(0);
  font-family: 'TildaSans';
}

.modal-wrap-add-course.active {
  transform: scale(1);
  z-index: 2;
}

.modal-add-course {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #494B42;
  margin-left: auto;
  margin-right: auto;
  max-width: 842px;
  padding: 48px;
  /* text-align: center; */
  font-family: 'TildaSans';
  border-radius: 2px;
  z-index: 3;
}

.modal-add-course-title {
  margin-bottom: 20px;
}

.btn-select-course {
  width: 340px;
  padding: 15px 40px;
  background-color: #878980;
  color: #F7F8F5;
  font-size: 18px;
  font-weight: 600;
  margin-right: 15px;
}

.btn-create-course {
  width: 340px;
  padding: 15px 40px;
  background-color: #C9D895;
  color: #494B42;
  font-size: 18px;
  font-weight: 600;
}

.modal-wrap-create-course {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(9, 10, 8, 0.5);
  backdrop-filter: blur(4px);
  transform: scale(0);
  font-family: 'TildaSans';
}

.modal-wrap-create-course.active {
  transform: scale(1);
  z-index: 2;
}

.modal-create-course {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #494B42;
  margin-left: auto;
  margin-right: auto;
  max-width: 842px;
  padding: 48px;
  /* text-align: center; */
  font-family: 'TildaSans';
  border-radius: 2px;
  z-index: 3;
}

.create-course-name,
.create-course-descr {
  width: 700px;
}

.modal-wrap-select-course {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(9, 10, 8, 0.5);
  backdrop-filter: blur(4px);
  transform: scale(0);
  font-family: 'TildaSans';
}

.modal-wrap-select-course.active {
  transform: scale(1);
  z-index: 2;
}

.modal-select-course {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #494B42;
  margin-left: auto;
  margin-right: auto;
  max-width: 842px;
  padding: 48px;
  /* text-align: center; */
  font-family: 'TildaSans';
  border-radius: 2px;
  z-index: 3;
}

.select-course {
  width: 700px;
}

.btn-delete-course,
.btn-cancel-course {
  padding: 5px 80px;
}

.search-course-wrap {
  padding: 0 80px;
  font-family: 'TildaSans';
  margin-bottom: 20px;
}

.search-course {
  padding: 10px 170px;
  background-color: #F7F8F5;
  color: #878980;
  border: 1px solid #C9D895;
  border-radius: 2px;
  outline: none;
}

.search-course::placeholder {
  color: #878980;
  font-family: 'TildaSans';
}

i.search-icon {
  width: 36px;
  height: 36px;
  line-height: 39px;
  text-align: center;
  margin-right: -33px;
  position: relative;
  z-index: 1;
  float: left;
}

i.search-icon+input {
  padding-left: 26px;
}

.courses-wrap-container {
  padding: 0 80px;
  font-family: 'TildaSans';
}

.mini-title {
  font-size: 20px;
  color: #494B42;
  margin: 0;
  margin-bottom: 10px;
}

.breakfast-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  min-height: 100px;
}

.lunch-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  min-height: 100px;
}

.dinner-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  min-height: 100px;
}

.all-hidden {
  display: none;
}

.all-hidden.active {
  display: block;
}

.feedback-icon {
  margin-right: 15px;
}

.feedback {
  align-items: center;
  padding: 10px 30px 10px 30px;
  background-color: #F7F8F5;
  color: #494B42;
  font-size: 16px;
  font-family: 'TildaSans';
  cursor: pointer;
}

.feedback-container {
  padding: 0 80px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.feedback-card {
  width: 300px;
  padding: 15px;
  background-color: #F7F8F5;
  font-family: 'TildaSans';
  margin-right: 20px;
  margin-bottom: 20px;
}

.start-container {
  display: flex;
  margin-bottom: 5px;
}

.feedback-text {
  padding: 10px;
  height: 120px;
  border: 1px solid #CFD0CA;
  border-radius: 2px;
  overflow-y: scroll;
  font-family: 'TildaSans';
  color: #494B42;
}

/* ::-webkit-scrollbar {
  width: 5px;
  height: 20px;
  background: transparent;
  background: #878980;
  border-radius: 1px;
  display: none;
} */

.wrap-head {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.user-fio-feed {
  color: #494B42;
  font-size: 12px;
  font-weight: 700;
}

.user-mail-feed {
  color: #878980;
  font-size: 12px;
}

.review-star-margin {
  margin-right: 7px;
}

.review-star.active {
  fill: #FBC96A;
}

.wrap-create-menu {
  padding: 0 80px;
  font-family: 'TildaSans';
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.btn-dinner {
  margin-right: auto;
}

.select-day-menu {
  margin-right: 20px;
}

.all-menu-save {
  margin-right: 20px;
  background-color: #C9D895;
}

.all-menu-save:hover {
  margin-right: 20px;
  color: #C9D895;
  background-color: #616847;
}

.input-time-end {
  margin-right: auto;
}

.signout-person {
  padding-right: 10px;
  margin-left: auto;
  cursor: pointer;
}

.strelka {
  margin-right: auto;
  font-family: 'TildaSans';
  font-size: 12px;
}

.strelka-right {
  margin-right: 10px;
  font-family: 'TildaSans';
  font-size: 12px;
}

.modal-exit {
  z-index: 1;
}

.group-title-wrap.download {
  display: flex;
  align-items: center;
}

.btn-download {
  background-color: rgb(201, 216, 149);
  color: #FEFFFF;
  font-size: 16p;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 2px;
  font-family: 'TildaSans';
}

.btn-download:hover {
  background-color: rgb(201 216 150 / 70%);
  color: #FEFFFF;
  font-size: 16p;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 2px;
  font-family: 'TildaSans';
}